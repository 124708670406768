export const URL_KEYS = {
  CORPORATE_REGISTRATION: {
    SAVE_SIGNATORY_MANDATE: 'save-account-signature-mandate',
    GET_ACCOUNT_SIGNATORY_MANDATE: 'get-account-signatory-mandate',
    GET_ACCOUNT_SIGNATORIES: 'get-account-signatories',
    INVITE_ACCOUNT_SIGNATORY: 'invite-account-signatory',
    UPDATE_ACCOUNT_SIGNATORY_MANDATE: 'update-account-signatory-mandate',
    UPDATE_ACCOUNT_SIGNATORY: 'update-account-signatory',
  },
  INDIVIDUAL_REGISTRATION: {
    UPDATE_CUSTOMER_PROFILE: 'update-profile',
  },
  TRANSACTIONS: {
    GET_BENEFICIARY_DETAILS: 'get_beneficiary_details',
  },
  COMMON: {
    GET_BANK_LIST: 'get-bank-list',
    GET_BANK_NAME: 'get-bank-name',
  },
  USER: {
    VIEW_PROFILE_LIST: 'view-profile-list',
  },
  PORTFOLIO: {
    GET_PORTFOLIO_CHART: 'get-portfolio-chart',
  },
  JOINT: {
    CREATE_JOINT_ACCOUNT: 'create-joint-account',
    ADD_JOINT_ACCOUNT_USER: 'joint-account-addition',
    GET_JOINT_ACCOUNT_INFO: 'get-joint-account-info',
    ADD_MEANS_OF_IDENTITY: 'add-means-of-identity-kyc',
    GET_JOINT_ACCOUNT_KYC: 'get-joint-account-kyc',
    UPDATE_JOINT_ACCOUNT_PROFILE: 'update-joint-account-profile',
    CREATE_KYC_LOCATION: 'create-joint-account-location-kyc',
    UPDATE_KYC_LOCATION: 'update-joint-account-location-kyc',
    ADD_JOINT_ACCOUNT_EMPLOYMENT_DETAILS:
      'add-joint-account-employment-details',
    UPDATE_JOINT_ACCOUNT_EMPLOYMENT_DETAILS:
      'update-joint-account-employment-details',
    ADD_JOINT_ACCOUNT_NEXT_OF_KIN_KYC: 'add-joint-account-next-of-kin-kyc',
    UPDATE_JOINT_ACCOUNT_NEXT_OF_KIN_KYC:
      'update-joint-account-next-of-kin-kyc',
  },
} as const;
